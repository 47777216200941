import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot
} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ICustomDialogConfig } from '../interfaces/generic.interface';
import { CustomDialogComponent } from '../../shared/components/custom-dialog/custom-dialog.component';
import { AuthService } from '../services/security/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FormDirtyComponentGuard implements CanDeactivate<unknown> {
  constructor(private dialog: MatDialog, private authService: AuthService) {}

  async canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Promise<boolean> {
    if (
      !component?.form?.dirty ||
      !!component?.isCompleted ||
      !this.authService.isLoggedIn()
    ) {
      return true;
    }

    const dialogRef = this.dialog.open<
      CustomDialogComponent,
      ICustomDialogConfig
    >(CustomDialogComponent, {
      data: {
        title: 'Atenção',
        subtitle: 'Ao sair você perderá todo o processo.',
        message: 'Salve para continuar em um outro momento.',
        confirmText: 'Salvar e sair',
        cancelText: 'Sair'
      }
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result) {
      const targetUrl = nextState?.url || '/';
      await component.onSaveDraft(targetUrl);
      return false;
    } else if (result === false) {
      return true;
    }
    return false;
  }
}

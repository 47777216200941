import { inject } from '@angular/core';
import { Router, type ResolveFn } from '@angular/router';
import { catchError, forkJoin, map, Observable, of, tap } from 'rxjs';
import {
  IGoal,
  IGoalAndActionPlan,
  IMember,
  IThemeGoal
} from '../interfaces/action-plans.interface';
import { IPotentialTheme } from '../interfaces/potential-themes.interface';
import { GoalsService } from '../services/goals.service';
import { MaterialityService } from '../services/materiality.service';

export const goalsListResolver: ResolveFn<Observable<IGoalAndActionPlan>> = (
  route,
  state
) => {
  const goalsService = inject(GoalsService);
  const router = inject(Router);

  const themesGoals$ = goalsService.getThemesGoals();
  const members$ = goalsService.getAvailableMembers();

  const request$ = forkJoin([themesGoals$, members$]).pipe(
    tap((response) => {
      const goalsAndActionPlan = response[0].data;

      if (!goalsAndActionPlan) {
        router.navigate(['/plataforma/metas/temas']);
      }

      goalsService.availableMembers.set(response[1].data);
    }),
    map((response) => response[0].data)
  );

  return request$;
};

export const themesGoalsListResolver: ResolveFn<
  Observable<{ themes: IPotentialTheme[]; goalsAndActionPlan: IThemeGoal[] }>
> = (route, state) => {
  const goalsService = inject(GoalsService);
  const materialityService = inject(MaterialityService);

  const themes$ = materialityService.getGeneralThemes();
  const goals$ = goalsService.getThemesGoals();

  const themesGoals$ = forkJoin([themes$, goals$]).pipe(
    map(([themes, goals]) => {
      return {
        themes: themes.data,
        goalsAndActionPlan: goals?.data?.goalsAndActionPlanThemes ?? []
      };
    })
  );

  return themesGoals$;
};

export const goalFormResolver: ResolveFn<Observable<IGoal>> = (
  route,
  state
) => {
  const goalsService = inject(GoalsService);
  const router = inject(Router);

  let formId = route.queryParams['meta'];

  if (formId) {
    const members$ = goalsService.availableMembers().length
      ? of(goalsService.availableMembers())
      : goalsService
          .getAvailableMembers()
          .pipe(map((response) => response.data));
    const goal$ = goalsService.getGoal(formId).pipe(
      map((response) => {
        response.data.metrics = response.data.metrics.map((metric) => {
          metric.initialDate = metric.initialDate.substring(0, 10) ?? null;
          metric.finalDate = metric.finalDate.substring(0, 10) ?? null;
          return metric;
        });

        return response.data;
      })
    );

    const request$ = forkJoin([members$, goal$]).pipe(
      tap((response) => goalsService.availableMembers.set(response[0])),
      map((response) => response[1]),
      catchError((error) => {
        router.navigate(['/plataforma/metas/gestao']);
        return of(null);
      })
    );

    return request$;
  }

  return {
    description: null,
    indicator: null,
    monitoringFrequency: null,
    method: null,
    ods: null,
    responsible: null,
    uploads: [],
    activities: [],
    metrics: [],
    members: [],
    actionPlans: []
  } as any;
};

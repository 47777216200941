import { Routes } from '@angular/router';
import { PublicPageComponent } from './containers/public-page/public-page.component';
import { FormTypeEnum } from './core/enums/diagnosis.enum';
import { authTokenValidateGuard } from './core/guards/auth-token-validate.guard';
import { authGuard } from './core/guards/auth.guard';
import { customerGuard } from './core/guards/customer.guard';
import { FormDirtyComponentGuard } from './core/guards/form-dirty.guard';
import { loginGuard } from './core/guards/login.guard';
import { mainDiagnosisDataGuard } from './core/guards/main-diagnosis-data.guard';
import { materialityDataGuard } from './core/guards/materiality-data.guard';
import { permissionGuard } from './core/guards/permission.guard';
import { stakeholderTokenValidateGuard } from './core/guards/stakeholder-token-validate.guard';
import { userGuard } from './core/guards/user.guard';
import { diagnosisFormResolver } from './core/resolvers/diagnosis-form.resolver';
import { diagnosisResultResolver } from './core/resolvers/diagnosis-result.resolver';
import {
  goalFormResolver,
  goalsListResolver,
  themesGoalsListResolver
} from './core/resolvers/goals-action-plan.resolver';
import { mainDiagnosisFormResolver } from './core/resolvers/main-diagnosis-form.resolver';
import {
  evaluateImpactResolver,
  materialityAnalysisResolver,
  materialityMatrixResolver,
  organizationContextResolver,
  potentialThemesResolver,
  relevanceThemesResolver,
  stakeholderGroupsResolver
} from './core/resolvers/materiality.resolver';
import { stakeholderResolver } from './core/resolvers/stakeholder.resolver';
import { AuthenticationComponent } from './modules/public/authentication/authentication.component';
import { InitialDiagnosisComponent } from './modules/public/initial-diagnosis/initial-diagnosis.component';
import { goalGuard } from './core/guards/goal.guard';

export const routes: Routes = [
  {
    path: '',
    component: PublicPageComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./modules/public/landing-home/landing-home.component')
      },
      {
        path: 'quem-somos',
        loadComponent: () =>
          import('./modules/public/info-company/info-company.component')
      },
      {
        path: 'diagnostico-inicial',
        component: InitialDiagnosisComponent,
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './modules/public/initial-diagnosis/initial-diagnosis-home/initial-diagnosis-home.component'
              )
          },
          {
            path: 'formulario',
            data: { formType: FormTypeEnum.Initial },
            resolve: {
              form: diagnosisFormResolver
            },
            loadComponent: () =>
              import(
                './modules/public/initial-diagnosis/initial-diagnosis-form/initial-diagnosis-form.component'
              )
          }
        ]
      },
      {
        path: 'autenticacao',
        component: AuthenticationComponent,
        canActivate: [loginGuard],
        children: [
          {
            path: '',
            redirectTo: 'login',
            pathMatch: 'full'
          },
          {
            path: 'login',
            loadComponent: () =>
              import('./modules/public/authentication/login/login.component')
          },
          {
            path: 'recuperar-senha',
            loadComponent: () =>
              import(
                './modules/public/authentication/recover-password/recover-password.component'
              )
          },
          {
            path: 'redefinir-senha',
            canActivate: [authTokenValidateGuard],
            loadComponent: () =>
              import(
                './modules/public/authentication/redefine-password/redefine-password.component'
              )
          },
          {
            path: 'criar-conta',
            canActivate: [authTokenValidateGuard],
            loadComponent: () =>
              import(
                './modules/public/authentication/create-account/create-account.component'
              )
          }
        ]
      }
    ]
  },
  {
    path: 'plataforma',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./containers/private-page/private-page.component'),
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        canActivate: [userGuard],
        loadComponent: () => import('./modules/private/home/home.component')
      },
      {
        path: 'materialidade',
        canActivate: [permissionGuard, userGuard],
        data: { requiredPermission: 'materiality-get' },
        children: [
          {
            path: '',
            redirectTo: 'inicio',
            pathMatch: 'full'
          },
          {
            path: 'inicio',
            loadComponent: () =>
              import(
                './modules/private/materiality/materiality-start/materiality-start.component'
              )
          },
          {
            path: 'analise',
            resolve: { data: materialityAnalysisResolver },
            loadComponent: () =>
              import(
                './modules/private/materiality/materiality-analisys/materiality-analisys.component'
              )
          },
          {
            path: 'analise/contexto-organizacional',
            canActivate: [materialityDataGuard],
            canDeactivate: [FormDirtyComponentGuard],
            resolve: { data: organizationContextResolver },
            loadComponent: () =>
              import(
                './modules/private/materiality/materiality-analisys/organizational-context/organizational-context.component'
              )
          },
          {
            path: 'analise/stakeholders',
            canActivate: [materialityDataGuard],
            loadComponent: () =>
              import(
                './modules/private/materiality/materiality-analisys/stakeholders-group/stakeholders-group.component'
              ),
            children: [
              {
                path: '',
                redirectTo: 'info-stakeholders',
                pathMatch: 'full'
              },
              {
                path: 'info-stakeholders',
                loadComponent: () =>
                  import(
                    './modules/private/materiality/materiality-analisys/stakeholders-group/info-stakeholders-group/info-stakeholders-group.component'
                  )
              },
              {
                path: 'identificacao-stakeholders',
                canDeactivate: [FormDirtyComponentGuard],
                resolve: { data: stakeholderGroupsResolver },
                loadComponent: () =>
                  import(
                    './modules/private/materiality/materiality-analisys/stakeholders-group/form-stakeholders-group/form-stakeholders-group.component'
                  )
              }
            ]
          },
          {
            path: 'analise/temas-potenciais',
            canActivate: [materialityDataGuard],
            loadComponent: () =>
              import(
                './modules/private/materiality/materiality-analisys/potential-themes/potential-themes.component'
              ),
            children: [
              {
                path: '',
                redirectTo: 'info',
                pathMatch: 'full'
              },
              {
                path: 'info',
                loadComponent: () =>
                  import(
                    './modules/private/materiality/materiality-analisys/potential-themes/info-themes/info-themes.component'
                  )
              },
              {
                path: 'mapeamento',
                canDeactivate: [FormDirtyComponentGuard],
                resolve: { data: potentialThemesResolver },
                loadComponent: () =>
                  import(
                    './modules/private/materiality/materiality-analisys/potential-themes/mapping-themes/mapping-themes.component'
                  )
              }
            ]
          },
          {
            path: 'analise/relevancia',
            canActivate: [materialityDataGuard],
            loadComponent: () =>
              import(
                './modules/private/materiality/materiality-analisys/relevance-themes/relevance-themes.component'
              ),
            children: [
              {
                path: '',
                redirectTo: 'info',
                pathMatch: 'full'
              },
              {
                path: 'info',
                loadComponent: () =>
                  import(
                    './modules/private/materiality/materiality-analisys/relevance-themes/info-relevance/info-relevance.component'
                  )
              },
              {
                path: 'gerenciamento',
                resolve: { data: relevanceThemesResolver },
                loadComponent: () =>
                  import(
                    './modules/private/materiality/materiality-analisys/relevance-themes/management-relevance/management-relevance.component'
                  )
              },
              {
                path: 'pesquisa/:id',
                canDeactivate: [FormDirtyComponentGuard],
                resolve: {
                  stakeholder: stakeholderResolver
                },
                loadComponent: () =>
                  import(
                    './modules/private/materiality/materiality-analisys/relevance-themes/research-relevance/research-relevance.component'
                  )
              }
            ]
          },
          {
            path: 'analise/impacto',
            canActivate: [materialityDataGuard],
            loadComponent: () =>
              import(
                './modules/private/materiality/materiality-analisys/evaluate-impact/evaluate-impact.component'
              ),
            children: [
              {
                path: '',
                redirectTo: 'info',
                pathMatch: 'full'
              },
              {
                path: 'info',
                loadComponent: () =>
                  import(
                    './modules/private/materiality/materiality-analisys/evaluate-impact/info-evaluate/info-evaluate.component'
                  )
              },
              {
                path: 'avaliar-temas',
                canDeactivate: [FormDirtyComponentGuard],
                resolve: { data: evaluateImpactResolver },
                loadComponent: () =>
                  import(
                    './modules/private/materiality/materiality-analisys/evaluate-impact/evaluate-impact-themes/evaluate-impact-themes.component'
                  )
              }
            ]
          },
          {
            path: 'analise/matriz',
            canActivate: [materialityDataGuard],
            resolve: { data: materialityMatrixResolver },
            loadComponent: () =>
              import(
                './modules/private/materiality/materiality-analisys/matrix/matrix.component'
              )
          }
        ]
      },
      {
        path: 'diagnostico',
        canActivate: [permissionGuard, userGuard],
        data: { requiredPermission: 'diagnostic-get' },
        children: [
          {
            path: '',
            redirectTo: 'info',
            pathMatch: 'full'
          },
          {
            path: 'info',
            loadComponent: () =>
              import(
                './modules/private/main-diagnosis/info-diagnosis/info-diagnosis.component'
              )
          },
          {
            path: 'onboarding',
            resolve: {
              data: mainDiagnosisFormResolver
            },
            loadComponent: () =>
              import(
                './modules/private/main-diagnosis/onboarding-diagnosis/onboarding-diagnosis.component'
              )
          },
          {
            path: 'formulario',
            canActivate: [mainDiagnosisDataGuard],
            canDeactivate: [FormDirtyComponentGuard],
            loadComponent: () =>
              import(
                './modules/private/main-diagnosis/main-diagnosis-form/main-diagnosis-form.component'
              )
          },
          {
            path: 'resultado/:id',
            canActivate: [mainDiagnosisDataGuard],
            resolve: {
              result: diagnosisResultResolver
            },
            loadComponent: () =>
              import(
                './modules/private/main-diagnosis/main-diagnosis-result/main-diagnosis-result.component'
              )
          }
        ]
      },
      {
        path: 'metas',
        canActivate: [permissionGuard, userGuard],
        data: { requiredPermission: 'goals-get' },
        children: [
          {
            path: '',
            redirectTo: 'info',
            pathMatch: 'full'
          },
          {
            path: 'info',
            loadComponent: () =>
              import(
                './modules/private/action-plans/action-plans-info/action-plans-info.component'
              )
          },
          {
            path: 'temas',
            canActivate: [permissionGuard],
            data: { requiredPermission: 'goals-edit' },
            resolve: { data: themesGoalsListResolver },
            loadComponent: () =>
              import(
                './modules/private/action-plans/action-plans-themes/action-plans-themes.component'
              )
          },
          {
            path: 'gestao',
            resolve: { data: goalsListResolver },
            loadComponent: () =>
              import(
                './modules/private/action-plans/onboarding-action-plans/onboarding-action-plans.component'
              )
          },
          {
            path: 'formulario',
            canActivate: [goalGuard, permissionGuard],
            data: { requiredPermission: 'goals-edit' },
            resolve: { data: goalFormResolver },
            loadComponent: () =>
              import(
                './modules/private/action-plans/action-plans-form/action-plans-form.component'
              )
          }
        ]
      },
      {
        path: 'gerenciamento-clientes',
        canActivate: [permissionGuard, customerGuard],
        data: { requiredPermission: 'company-list' },
        loadComponent: () =>
          import(
            './modules/private/customer-management/customer-management.component'
          )
      },
      {
        path: 'gerenciamento-usuarios',
        canActivate: [permissionGuard],
        data: { requiredPermission: 'user-list' },
        loadComponent: () =>
          import('./modules/private/user-management/user-management.component')
      },
      {
        path: 'perfis-acesso',
        canActivate: [permissionGuard, customerGuard],
        data: { requiredPermission: 'permission-list' },
        loadComponent: () =>
          import('./modules/private/access-profile/access-profile.component')
      }
    ]
  },
  {
    path: 'pesquisa-relevancia',
    canActivate: [stakeholderTokenValidateGuard],
    loadComponent: () =>
      import(
        './modules/public/relevance-themes-research/relevance-themes-research.component'
      )
  },

  {
    path: '**',
    redirectTo: ''
  }
];

import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { DiagnosisService } from '../services/diagnosis.service';
import { Observable } from 'rxjs';
import { IDiagnosisData } from '../interfaces/diagnosis.interface';

export const mainDiagnosisFormResolver: ResolveFn<
  Observable<IDiagnosisData>
> = (route, state) => {
  const diagnosisService = inject(DiagnosisService);

  return diagnosisService.getDiagnosisData();
};

import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { GoalsService } from '../services/goals.service';

export const goalGuard: CanActivateFn = (route, state) => {
  const goalsService = inject(GoalsService);
  const router = inject(Router);

  let formId = route.queryParams['meta'];

  if(!formId && !goalsService.configGoal()) {
    router.navigate(['/plataforma/metas/gestao']);
    return false;
  }

  return true;
};

import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { DiagnosisService } from '../services/diagnosis.service';
import { Observable, map } from 'rxjs';
import { IDiagnosisForm } from '../interfaces/diagnosis.interface';
import {
  EmployeeCountData,
  initialDiagnosisCompany
} from '../utils/diagnosis-data';
import { TopicEnum, TopicValues } from '../enums/diagnosis.enum';

export const diagnosisFormResolver: ResolveFn<Observable<IDiagnosisForm>> = (
  route,
  state
) => {
  const diagnosisService = inject(DiagnosisService);

  return diagnosisService.getDiagnosisForm(route.data['formType']).pipe(
    map((form) => {
      const companyForm = { ...initialDiagnosisCompany };
      const employeeCountForm = { ...EmployeeCountData };

      form.segments.unshift(companyForm);

      let segment = form.segments.find(
        (segment) => TopicValues[segment.topic] === TopicEnum.SOCIAL
      );

      if (segment) {
        segment.blocks.unshift(employeeCountForm);
      }

      return form;
    })
  );
};

import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { CustomerService } from '../services/customer.service';
import { AuthService } from '../services/security/auth.service';

export const userGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const customerService = inject(CustomerService);
  const router = inject(Router);

  if (!authService.userIsCustomer() && !customerService.customerActive()) {
    router.navigate(['/plataforma/gerenciamento-clientes']);
    return false;
  }

  return true;
};

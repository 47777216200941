import { CompanyEmployeeCountEnum } from './../enums/diagnosis.enum';
import {
  CompanyLegalModelEnum,
  CompanySizeEnum,
  EsgLevelEnum
} from '../enums/diagnosis.enum';
import { ISegment } from '../interfaces/diagnosis.interface';
import { QuestionType } from './../enums/question-type.enum';

export const initialDiagnosisCompany: ISegment = {
  topic: 0,
  blocks: [
    {
      id: '001',
      title:
        'Para receber o resultado personalizado do seu diagnóstico, por favor, informe seus dados',
      order: 1,
      allowAvoidBlock: false,
      questions: [
        {
          id: 'name',
          title: null,
          subtitle: null,
          label: 'Nome',
          quote: null,
          order: 1,
          type: QuestionType.TEXT,
          answers: [],
          userAnswers: []
        },
        {
          id: 'email',
          title: null,
          subtitle: null,
          label: 'E-mail corporativo',
          quote: null,
          order: 1,
          type: QuestionType.TEXT,
          answers: [],
          userAnswers: []
        }
      ]
    },
    {
      id: '002',
      title: null,
      order: 2,
      allowAvoidBlock: false,
      questions: [
        {
          id: 'corporateName',
          title: 'Informe a Razão social da organização.',
          subtitle: null,
          label: null,
          quote: null,
          order: 1,
          type: QuestionType.TEXTAREA,
          answers: [],
          userAnswers: []
        },
        {
          id: 'state',
          title: 'Informe a localização da sede da organização.',
          subtitle: null,
          label: 'Estado',
          quote: null,
          order: 1,
          type: QuestionType.SELECT,
          answers: [],
          userAnswers: []
        },
        {
          id: 'city',
          title: null,
          subtitle: null,
          label: 'Cidade',
          quote: null,
          order: 1,
          type: QuestionType.SELECT,
          answers: [],
          userAnswers: []
        }
      ]
    },
    {
      id: '003',
      title: null,
      order: 3,
      allowAvoidBlock: false,
      questions: [
        {
          id: 'segment',
          title: 'Qual o segmento/ramo de atuação da organização.',
          subtitle: null,
          label: null,
          quote: null,
          order: 1,
          type: QuestionType.OPTION,
          answers: [
            {
              id: 'Manufatura',
              title: 'Manufatura'
            },
            {
              id: 'Varejo',
              title: 'Varejo'
            },
            {
              id: 'Serviço',
              title: 'Serviço'
            },
            {
              id: 'Saúde',
              title: 'Saúde'
            },
            {
              id: 'Educação',
              title: 'Educação'
            },
            {
              id: 'Transporte e Logística',
              title: 'Transporte e Logística'
            },
            {
              id: 'Energia',
              title: 'Energia'
            },
            {
              id: 'Imobiliário',
              title: 'Imobiliário'
            },
            {
              id: 'Outro',
              title: 'Outro',
              isOtherOption: true
            }
          ],
          userAnswers: []
        },
        {
          id: 'legalModel',
          title: 'Qual é o tipo da organização (modelo jurídico)?',
          subtitle: null,
          label: null,
          quote: null,
          order: 2,
          type: QuestionType.OPTION,
          answers: [
            {
              id: CompanyLegalModelEnum.PRIVATE_COMPANY,
              title: 'Empresa privada'
            },
            {
              id: CompanyLegalModelEnum.OUTSOURCED,
              title: 'Terceiro setor'
            },
            {
              id: CompanyLegalModelEnum.COOPERATIVE,
              title: 'Cooperativa'
            },
            {
              id: CompanyLegalModelEnum.PUBLIC_INSTITUTION,
              title: 'Instituição pública'
            },
            {
              id: CompanyLegalModelEnum.OTHERS,
              title: 'Outro',
              isOtherOption: true
            }
          ],
          userAnswers: []
        }
      ]
    },
    {
      id: '004',
      title: null,
      order: 4,
      allowAvoidBlock: false,
      questions: [
        {
          id: 'size',
          title: 'Informe o porte da empresa de acordo com o seu faturamento:',
          subtitle: null,
          label: null,
          quote: null,
          order: 1,
          type: QuestionType.OPTION,
          answers: [
            {
              id: CompanySizeEnum.MICRO,
              title: 'Microempresa - Faturamento anual até R$ 360 mil'
            },
            {
              id: CompanySizeEnum.SMALL,
              title:
                'Empresa de Pequeno Porte - Faturamento anual entre R$ 360 mil e R$ 4,8 milhões'
            },
            {
              id: CompanySizeEnum.MEDIUM,
              title:
                'Empresa de Médio Porte - Faturamento anual entre R$ 4,8 milhões e R$ 20 milhões'
            },
            {
              id: CompanySizeEnum.LARGE,
              title:
                'Empresa de Grande Porte - Faturamento anual acima de R$ 20 milhões'
            }
          ],
          userAnswers: []
        }
      ]
    },
    {
      id: '005',
      title: null,
      order: 5,
      allowAvoidBlock: false,
      questions: [
        {
          id: 'esgLevel',
          title:
            'Qual <strong>das alternativas</strong> a seguir melhor representa o que diz respeito à Política ESG da organização?',
          subtitle: null,
          label: null,
          quote: null,
          order: 1,
          type: QuestionType.OPTION,
          answers: [
            {
              id: EsgLevelEnum.NOT_IMPLEMENTED,
              title: 'Não temos implementada'
            },
            {
              id: EsgLevelEnum.IMPLEMENTING,
              title: 'Estamos em fase inicial de implementação'
            },
            {
              id: EsgLevelEnum.NON_RESULTS,
              title:
                'Já implementamos, mas ainda não temos resultados consistentes para avaliação'
            },
            {
              id: EsgLevelEnum.CONSISTENT_RESULTS,
              title:
                'Já implementamos, e temos resultados consistentes para a avaliação'
            },
            {
              id: EsgLevelEnum.REFERENCE_COMPANY,
              title: 'Somos referência em nosso mercado de atuação'
            }
          ],
          userAnswers: []
        }
      ]
    }
  ]
};

export const EmployeeCountData = {
  id: '006',
  title: null,
  order: 1,
  allowAvoidBlock: false,
  questions: [
    {
      id: 'employeeCount',
      title: 'Informe quantos colaboradores trabalham na empresa atualmente',
      subtitle: null,
      label: null,
      quote: null,
      order: 1,
      type: QuestionType.OPTION,
      answers: [
        {
          id: CompanyEmployeeCountEnum.ZERO_TO_TEN,
          title: '0 a 10 colaboradores'
        },
        {
          id: CompanyEmployeeCountEnum.ELEVEN_TO_FIFTY,
          title: '11 a 50 colaboradores'
        },
        {
          id: CompanyEmployeeCountEnum.FIFTY_ONE_TO_TWO_HUNDRED,
          title: '51 a 200 colaboradores'
        },
        {
          id: CompanyEmployeeCountEnum.TWO_HUNDRED_AND_ONE_TO_FIVE_HUNDRED,
          title: '201 a 500 colaboradores'
        },
        {
          id: CompanyEmployeeCountEnum.FIVE_HUNDRED_AND_ONE_TO_ONE_THOUSAND,
          title: '501 a 1000 colaboradores'
        },
        {
          id: CompanyEmployeeCountEnum.MORE_THAN_ONE_THOUSAND,
          title: 'Mais de 1000 colaboradores'
        }
      ],
      userAnswers: []
    }
  ]
};

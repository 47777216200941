import { Component, OnInit } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { LandingService } from '../../../core/services/landing.service';
import { AuthService } from '../../../core/services/security/auth.service';

@Component({
  selector: 'app-header-public',
  standalone: true,
  imports: [RouterModule, MatMenuModule],
  templateUrl: './header-public.component.html',
  styleUrl: './header-public.component.scss'
})
export class HeaderPublicComponent implements OnInit {
  btnText = 'Login';
  showMenu = false;

  constructor(
    private landingService: LandingService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.btnText = 'Acessar plataforma';
    }
  }

  scrollToForm(): void {
    this.landingService.scrollToForm.next(true);
  }

  routeToAccess(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/plataforma']);
    } else {
      this.router.navigate(['/autenticacao/login']);
    }
  }
}

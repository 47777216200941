import { inject } from '@angular/core';
import type { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/security/auth.service';

const PERMISSION = 'requiredPermission';

export const permissionGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);

  const requiredPermission = route.data[PERMISSION];

  if(requiredPermission) {
    const hasPermission = authService.hasPermission(requiredPermission);

    if (!hasPermission) {
      return false;
    }
  }
  
  return true;
};

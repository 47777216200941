import type { ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { IDiagnosisResult } from '../interfaces/diagnosis.interface';
import { IGenericResponse } from '../interfaces/generic.interface';
import { inject } from '@angular/core';
import { DiagnosisService } from '../services/diagnosis.service';

export const diagnosisResultResolver: ResolveFn<
  Observable<IGenericResponse<IDiagnosisResult>>
> = (route, state) => {
  const diagnosisService = inject(DiagnosisService);
  let id = route.params['id'];

  return diagnosisService.getDiagnosisResult(id);
};

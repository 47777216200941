import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { AlertService } from '../services/alert.service';

export const stakeholderTokenValidateGuard: CanActivateFn = (route, state) => {
  const alertService = inject(AlertService);
  const router = inject(Router);

  const token = route.queryParamMap.get('token') ?? '';

  if (!token) {
    alertService.show(
      'error',
      `Seu link de pesquisa é inválido. Tente novamente!`
    );
    router.navigate([`/`]);
    return false;
  } else {
    return true;
  }
};

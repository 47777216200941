import {
  animate,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoaderService } from '../../core/services/loader.service';
import { HeaderPublicComponent } from '../../shared/core/header-public/header-public.component';
import { LoaderComponent } from '../../shared/core/loader/loader.component';

@Component({
  selector: 'app-public-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HeaderPublicComponent,
    LoaderComponent
  ],
  templateUrl: './public-page.component.html',
  styleUrl: './public-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeAnimation', [
      transition('* <=> *', [
        query(
          ':enter',
          [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))],
          { optional: true }
        )
      ])
    ])
  ]
})
export class PublicPageComponent {
  loaderService = inject(LoaderService);
}

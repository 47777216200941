import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { DiagnosisService } from '../services/diagnosis.service';

export const mainDiagnosisDataGuard: CanActivateFn = (route, state) => {
  const diagnosisService = inject(DiagnosisService);
  const router = inject(Router);

  if (!diagnosisService.diagnosisData()) {
    router.navigate(['/plataforma/diagnostico/onboarding']);
    return false;
  }

  return true;
};

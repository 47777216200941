import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { TokenService } from '../services/security/token.service';

export const authTokenValidateGuard: CanActivateFn = (route, state) => {
  const tokenService = inject(TokenService);
  const alertService = inject(AlertService);
  const router = inject(Router);

  const token = route.queryParamMap.get('token') ?? '';
  const msg = route.url[0].path.includes('redefinir')
    ? 'redefinição'
    : 'criação';
  const path = msg === 'redefinição' ? 'recuperar-senha' : 'login';

  if (!token) {
    alertService.show(
      'error',
      `Seu link de ${msg} de senha é inválido. Tente novamente!`
    );
    router.navigate([`/autenticacao/${path}`]);
    return false;
  } else {
    return true;
  }
};

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  animate,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';
import { ContainerComponent } from '../../../shared/core/container/container.component';

@Component({
  selector: 'app-initial-diagnosis',
  standalone: true,
  imports: [CommonModule, RouterModule, ContainerComponent],
  templateUrl: './initial-diagnosis.component.html',
  styleUrl: './initial-diagnosis.component.scss',
  animations: [
    trigger('fadeAnimation', [
      transition('* <=> *', [
        query(
          ':enter',
          [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))],
          { optional: true }
        )
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InitialDiagnosisComponent {}

import { Router, type CanActivateFn } from '@angular/router';
import { MaterialityService } from '../services/materiality.service';
import { inject } from '@angular/core';

export const materialityDataGuard: CanActivateFn = (route, state) => {
  const materialityService = inject(MaterialityService);
  const router = inject(Router);

  if (!materialityService.materialityStatusData()) {
    router.navigate(['/plataforma/materialidade/analise']);
    return false;
  }

  return true;
};

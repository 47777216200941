<header class="header-public">
  <nav class="nav-public">
    <a class="nav-item logo-container" routerLink="/">
      <img class="logo-img" src="/assets/images/logo.svg" alt="Logo conexão" />
    </a>
    <a
      class="nav-item item-action menu-item mt-8"
      [routerLink]="['/diagnostico-inicial']"
      routerLinkActive="menu-active"
      [routerLinkActiveOptions]="{ exact: false }"
      ><span>Diagnóstico</span>
      <div class="bar mt-8"></div
    ></a>
    <a
      class="nav-item item-action menu-item mt-8"
      [routerLink]="['/quem-somos']"
      routerLinkActive="menu-active"
      [routerLinkActiveOptions]="{ exact: true }"
      ><span>Quem Somos</span>
      <div class="bar mt-8"></div
    ></a>
  </nav>
  <div class="buttons-container">
    <button
      class="btn-secondary px-16 py-10 font-regular item-action"
      (click)="routeToAccess()"
    >
      {{ btnText }}
    </button>
    <button
      class="btn-primary px-16 py-10 font-regular item-action"
      (click)="scrollToForm()"
      [routerLink]="['/']"
    >
      Solicitar demonstração
    </button>
    <button class="btn-icon menu" mat-button [matMenuTriggerFor]="menu">
      <img src="/assets/icons/menu.svg" alt="menu" />
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['/diagnostico-inicial']">
        <span>Diagnóstico inicial</span>
      </button>
      <button mat-menu-item [routerLink]="['/quem-somos']">
        <span>Quem somos</span>
      </button>
    </mat-menu>
  </div>
</header>

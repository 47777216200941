import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { Observable, catchError, forkJoin, of } from 'rxjs';
import { IGenericResponse } from '../interfaces/generic.interface';
import { IMaterialityMatrixTheme } from '../interfaces/materiality-matrix-theme.interface';
import { IMaterialityStatus } from '../interfaces/materiality.interface';
import { IOrganizationContext } from '../interfaces/organization-context.interface';
import {
  IMaterialityPotentialThemes,
  IPotentialTheme,
  IPotentialThemesEvaluate
} from '../interfaces/potential-themes.interface';
import { IMaterialityRelevance } from '../interfaces/stakeholder.interface';
import { MaterialityService } from '../services/materiality.service';

export const materialityAnalysisResolver: ResolveFn<
  Observable<IMaterialityStatus[]>
> = (route, state) => {
  const materialityService = inject(MaterialityService);

  return materialityService.getMaterialityStatus();
};

export const organizationContextResolver: ResolveFn<
  Observable<IOrganizationContext>
> = (route, state) => {
  const materialityService = inject(MaterialityService);

  return materialityService.getOrganizationalContext().pipe(
    catchError((error) => {
      return of({} as IOrganizationContext);
    })
  );
};

export const stakeholderGroupsResolver: ResolveFn<
  Observable<IGenericResponse<IMaterialityRelevance>>
> = (route, state) => {
  const materialityService = inject(MaterialityService);
  const router = inject(Router);

  return materialityService.getStakeholderGroups().pipe(
    catchError((error) => {
      router.navigate(['/plataforma/materialidade/analise']);
      return of({} as any);
    })
  );
};

export const potentialThemesResolver: ResolveFn<
  Observable<
    [
      IGenericResponse<IMaterialityPotentialThemes<IPotentialTheme>>,
      IGenericResponse<IPotentialTheme[]>
    ]
  >
> = (route, state) => {
  const materialityService = inject(MaterialityService);

  const potentialThemes$ = materialityService.getPotentialThemes();
  const generalThemes$ = materialityService.getGeneralThemes();
  const router = inject(Router);

  const join$ = forkJoin([potentialThemes$, generalThemes$]).pipe(
    catchError((error) => {
      router.navigate(['/plataforma/materialidade/analise']);
      return of({} as any);
    })
  );

  return join$;
};

export const relevanceThemesResolver: ResolveFn<
  Observable<IGenericResponse<IMaterialityRelevance>>
> = (route, state) => {
  const materialityService = inject(MaterialityService);
  const router = inject(Router);

  return materialityService.getMaterialityRelevance().pipe(
    catchError((error) => {
      router.navigate(['/plataforma/materialidade/analise']);
      return of({} as any);
    })
  );
};

export const evaluateImpactResolver: ResolveFn<
  Observable<
    IGenericResponse<IMaterialityPotentialThemes<IPotentialThemesEvaluate>>
  >
> = (route, state) => {
  const materialityService = inject(MaterialityService);
  const router = inject(Router);

  return materialityService.getEvaluatePotentialThemes().pipe(
    catchError((error) => {
      router.navigate(['/plataforma/materialidade/analise']);
      return of({} as any);
    })
  );
};

export const materialityMatrixResolver: ResolveFn<
  Observable<
    IGenericResponse<IMaterialityPotentialThemes<IMaterialityMatrixTheme>>
  >
> = (route, state) => {
  const materialityService = inject(MaterialityService);
  const router = inject(Router);

  return materialityService.getMaterialityMatrix().pipe(
    catchError((error) => {
      router.navigate(['/plataforma/materialidade/analise']);
      return of({} as any);
    })
  );
};

import type {
  HttpErrorResponse,
  HttpInterceptorFn
} from '@angular/common/http';
import { catchError, of, throwError } from 'rxjs';
import { AlertService } from '../services/alert.service';
import { inject } from '@angular/core';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const alertService = inject(AlertService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (!req.headers.get('handleErrorCustom')) {
        if (error.status === 404) {
          return of(null as any);
        }
        // Deixar o erro que vem do servidor
        alertService.show('error', error.error.errors[0].message);
      }
      return throwError(() => new Error(error.message));
    })
  );
};

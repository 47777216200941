import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { IGenericResponse } from '../interfaces/generic.interface';
import { IStakeholder } from '../interfaces/stakeholder.interface';
import { MaterialityService } from '../services/materiality.service';

export const stakeholderResolver: ResolveFn<
  Observable<IGenericResponse<IStakeholder>>
> = (route, state) => {
  const materialityService = inject(MaterialityService);
  const id = route.params['id'];

  return materialityService.getSurveyByStakeholder(id);
};
